import React from 'react';
import { GetServerSidePropsContext, NextPage } from 'next';
import ScreenCbtHome from 'src/app.feature/academy/screen/ScreenCbtHome';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import requestPrefetchQueryAPIList from 'src/lib/api/ssr/requestPrefetchQueryAPIList';
import { convertExamQueryString } from 'app.feature/academy/module/convertExamQueryString';
import requestServerSideAPI from 'src/lib/api/ssr/requestServerSideAPI';

type ExamList = {
  certificateExams: any[];
  publicCompanyExams: any[];
  corporateExams: any[];
};

type CbtPageProps = {
  title: string;
  examParentCategory: any;
  currentUser: any;
  examList: ExamList;
  likeList: any;
  currentHost: string;
};

const CbtPage: NextPage<CbtPageProps> = () => {
  return <ScreenCbtHome />;
};

export default CbtPage;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const queryClient = new QueryClient();

  try {
    const examTypes = ['/apiV2/exam-types'];
    const bestExams = ['/apiV2/exams/best'];

    const APIList = [examTypes, bestExams];

    await requestPrefetchQueryAPIList({
      queryClient,
      APIList,
    });

    const examTypeIds = queryClient.getQueryState<any>(examTypes)?.data.map((item: any) => item.id);

    const examAPIList = examTypeIds.map((id: number) => {
      return ['/apiV2/exams', { examTypeIds: id }];
    });

    const examList = [`/apiV2/exams`, convertExamQueryString(context.query)];

    await requestPrefetchQueryAPIList({
      queryClient,
      APIList: [...examAPIList, examList],
      options: { indices: false },
    });

    // 사용자가 좋아요한 시험목록
    const userLikes = ['/apiV2/user/me/likes/exams'];
    await queryClient.fetchQuery(userLikes, () =>
      requestServerSideAPI(userLikes).catch((error) => {
        throw error;
      })
    );
  } catch (error: any) {
    if (error?.response?.status !== 401) {
      console.error('#err-web-server: ', error.toString());
      throw error.response;
    }
  }

  const dehydratedState = dehydrate(queryClient);
  return {
    props: {
      title: '시험 CBT',
      dehydratedState,
    },
  };
}
